import React from "react"
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"

function renderImage(file) {
  return <Img fluid={file.node.childImageSharp.fluid} />
}

const GetImage = ({ src }) => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            file: allFile(filter: { extension: { regex: "/(webp)/" } }) {
              edges {
                node {
                  extension
                  relativePath
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              totalCount
            }
          }
        `}
        render={({ file }) =>
          renderImage(file.edges.find(image => image.node.relativePath === src))
        }
      />
    </>
  )
}

export default GetImage
