import {
  /*faInstagram,*/
  faGithubAlt,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

import PortfolioIMG from "../images/projects/portfolio.webp"
import weatherIMG from "../images/projects/weather.webp"
import kaiwaIMG from "../images/projects/chat.webp"
import Quiz from "../images/projects/quiz.webp"
import Notes from "../images/projects/notes.webp"
import VueTrello from "../images/projects/vue-trello.webp"

export const SOCIALLINKS = [
  {
    icon: faGithubAlt,
    url: "https://github.com/blopa2000",
    name: "github",
  },
  {
    icon: faTwitter,
    url: "https://twitter.com/jpagudelo2000",
    name: "twitter",
  },
  // {
  //   icon: faInstagram,
  //   url: "https://www.instagram.com/jpblopa/",
  //   name: "instagram",
  // },
  {
    icon: faLinkedinIn,
    url: "https://www.linkedin.com/in/juan-pablo-agudelo-castro/",
    name: "linkedin",
  },
]

export const MENU = [
  { to: "/#home", name: "inicio" },
  { to: "/#about", name: "sobre mi" },
  { to: "/#softSkills", name: "habilidades blandas" },
  { to: "/#portfolio", name: "portafolio" },
  { to: "/#contact", name: "contactame" },
]

export const PORTFOLIO = [
  {
    title: "Notes",
    description:
      "Una aplicación en la cual podrás crear notas para uso personal del día a día y en cualquier momento tendrás la posibilidad, de borrar o editar tus notas cuando lo necesites.",
    technologies: ["React", "scss", "firebase", "react-masonry-css", "formik"],
    github: true,
    urlGitHub: "https://github.com/blopa2000/notes-react-firebase",
    link: true,
    urlLink: "https://notes-react-1bf24.web.app/",
    image: Notes,
  },
  {
    title: "kaiwa",
    description:
      "Este proyecto es una creación de un chat en línea realizado en angular, el cual los usuarios pueden interactuar entre si.",
    technologies: ["angular", "firebase"],
    github: true,
    urlGitHub: "https://github.com/blopa2000/kaiwa",
    link: true,
    urlLink: "https://kaiwa-faff5.web.app/",
    image: kaiwaIMG,
  },
  {
    title: "Weather",
    description:
      "Una página para visualizar el clima, en la cual se puede buscar el clima de diferentes países.",
    technologies: ["Angular", "typescript", "OpenWeather", "scss"],
    github: true,
    urlGitHub: "https://github.com/blopa2000/weather-app",
    link: true,
    urlLink: "https://weather-app-h5vn.vercel.app/",
    image: weatherIMG,
  },
  {
    title: "Portafolio",
    description:
      "Este es un proyecto que deseaba realizar para así mostrar mi formación académica, también poder brindar demostraciones de mi trabajo y aptitudes que afirmo poseer.",
    technologies: ["gatsby.js", "scrollreveal", "netlify"],
    github: false,
    urlGitHub: "",
    link: true,
    urlLink: "/",
    image: PortfolioIMG,
  },

  {
    title: "QuizApp",
    description:
      "Es una aplicación creada para realizar y/o crear cuestionarios, además de que podrás visualizar tu resultado y ver quienes han realizado tus cuestionarios.",
    technologies: ["angular", "firebase", "Material-angular"],
    github: true,
    urlGitHub: "https://github.com/blopa2000/quiz-app",
    link: true,
    urlLink: "https://quiz-67c39.web.app/entry",
    image: Quiz,
  },
  {
    title: "Vue-Trello",
    description:
      "Este proyecto está basado en trello, con él tendrás la oportunidad de crear tableros y agrupar por tarjetas, para gestionar tus tareas de una forma más organizada.",
    technologies: [
      "vue",
      "vue-drag-n-drop",
      "vuetify",
      "firebase",
      "vuex",
      "sass",
    ],
    github: true,
    urlGitHub: "https://github.com/blopa2000/vue-trello",
    link: true,
    urlLink: "https://vue-trello-90ca6.web.app/login",
    image: VueTrello,
  },
]
