import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import Icons from "./icons"
import BtnTheme from "./btntheme"

import { MENU } from "../constants/information"

import ButtonClick from "../audio/button-click.mp3"

const Menu = ({ openMenu, handleOpenMenu }) => {
  const audio = new Audio(ButtonClick)

  const handleClick = e => {
    if (e.target.dataset.zone === "to-close") {
      handleOpenMenu(false)
    }
  }
  return (
    <div
      data-zone="to-close"
      className={`menu ${openMenu ? "" : "menu--hidden"}`}
      onClick={handleClick}
      role="presentation"
    >
      <div className="menu__box-items">
        <div
          className={`menu__layout-items ${
            openMenu ? "menu__layout-visibility" : ""
          }`}
        >
          <div className="menu__container-button">
            <button
              className="btn btn__icon"
              onClick={() => {
                handleOpenMenu(false)
                audio.play()
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </button>
          </div>
          <div className="menu__container-items">
            <ul>
              {MENU.map((element, id) => (
                <li key={id}>
                  <Link to={element.to} data-zone="to-close">
                    {element.name}
                  </Link>
                </li>
              ))}

              <li className="btn-theme-show">
                <BtnTheme handleOpenMenu={handleOpenMenu} />
              </li>
            </ul>
          </div>
          <div className="menu__container-icons">
            <Icons primary={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
