import React, { useState, useContext } from "react"
import { GlobalDispathContect } from "../context/GlobalContextProvider"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons"

import Click from "../audio/click.mp3"

const Btntheme = ({ theme = false, handleOpenMenu }) => {
  const [themeChange, setThemeChange] = useState(true)
  const dispath = useContext(GlobalDispathContect)
  const audio = new Audio(Click)

  const handleTheme = () => {
    dispath({ type: "TOGGLE_THEME" })
    if (document.body.classList.contains("theme-light")) {
      document.body.classList.remove("theme-light")
      setThemeChange(true)
    } else {
      document.body.classList.add("theme-light")
      setThemeChange(false)
    }
    audio.play()

    if (handleOpenMenu) handleOpenMenu(false)
  }

  return (
    <button
      className={`btn  btn__icon btn__theme ${
        theme ? "btn__icon-theme " : ""
      } ${themeChange ? "" : "btn__theme-moon"}`}
      onClick={handleTheme}
    >
      <FontAwesomeIcon icon={faSun} size="2x" />
      <FontAwesomeIcon icon={faMoon} size="2x" />
    </button>
  )
}

export default Btntheme
