import React, { useState } from "react"

import Menu from "./menu"
import BtnMenu from "./btnMenu"
import BtnTheme from "./btntheme"

const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const handleOpenMenu = isHidden => {
    setOpenMenu(isHidden)
  }
  return (
    <>
      <div className="sidebar">
        <BtnMenu handleOpenMenu={handleOpenMenu} theme={true} />
        <div className="line" />
        <a href="mailto:jpagudelo2000@gmail.com" className="email">
          <span>Jpagudelo2000@gmail.com</span>
        </a>
        <div className="line" />
        <BtnTheme theme={true} />
      </div>
      <Menu openMenu={openMenu} handleOpenMenu={handleOpenMenu} />
    </>
  )
}

export default Sidebar
