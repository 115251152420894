import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SOCIALLINKS } from "../constants/information"

const Icons = ({ primary = false, hover = false }) => {
  return (
    <div>
      {SOCIALLINKS.map((element, id) => (
        <a
          key={id}
          href={element.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`icon ${primary ? "icon__primary" : ""} ${
            hover ? "icon__hover" : ""
          }`}
          data-sal="zoom-in"
          data-sal-delay={id + "00"}
          data-sal-easing="ease"
        >
          <FontAwesomeIcon icon={element.icon} />
        </a>
      ))}
    </div>
  )
}

export default Icons
