import React, { useState, useContext } from "react"
import { Link } from "gatsby"

import BtnMenu from "./btnMenu"
import Menu from "./menu"
import GetImage from "./GetImage"
import { GlobalStateContect } from "../context/GlobalContextProvider"

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const state = useContext(GlobalStateContect)

  const handleOpenMenu = isHidden => {
    setOpenMenu(isHidden)
  }

  return (
    <>
      <header className="navbar">
        <nav>
          <Link to="/#home">
            {state?.theme === "dark" ? (
              <GetImage src="logo/logo.webp" />
            ) : (
              <GetImage src="logo/logo-light.webp" />
            )}
          </Link>

          <BtnMenu handleOpenMenu={handleOpenMenu} theme={true} />
        </nav>
      </header>
      <Menu openMenu={openMenu} handleOpenMenu={handleOpenMenu} />
    </>
  )
}

export default Navbar
