import React from "react"

export const GlobalStateContect = React.createContext()
export const GlobalDispathContect = React.createContext()

const initialState = {
  theme: "dark",
}

const reducer = (state, { type }) => {
  switch (type) {
    case "TOGGLE_THEME":
      return {
        ...state,
        theme: state.theme === "dark" ? "light" : "dark",
      }

    default:
      throw new Error("Bad Action type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispath] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContect.Provider value={state}>
      <GlobalDispathContect.Provider value={dispath}>
        {children}
      </GlobalDispathContect.Provider>
    </GlobalStateContect.Provider>
  )
}

export default GlobalContextProvider
