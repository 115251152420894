import React from "react"
import PropTypes from "prop-types"

import Sidebar from "./sidebar"
import Navbar from "./navbar"
import GlobalContextProvider from "./../context/GlobalContextProvider"

import "../styles/index.scss"

const Layout = ({ children }) => {
  return (
    <GlobalContextProvider>
      <main className="container">
        <Navbar />
        {children}
        <Sidebar />
        <footer className="footer">
          <div className="line line-footer" />
          <div>
            <p className="text">
              Copyright © {new Date().getFullYear()} ⭐Juan Pablo Agudelo
              Castro⭐
            </p>
            <p className="text">All Rights Reserved</p>
          </div>
        </footer>
      </main>
    </GlobalContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
