import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpaceShuttle } from "@fortawesome/free-solid-svg-icons"

import ButtonClick from "../audio/button-click.mp3"

const BtnMenu = ({ handleOpenMenu, theme = false }) => {
  const audio = new Audio(ButtonClick)

  return (
    <>
      <button
        className={`btn  btn__icon btn__rotate-icon ${
          theme ? "btn__icon-theme" : ""
        }`}
        onClick={() => {
          handleOpenMenu(true)
          audio.play()
        }}
      >
        <FontAwesomeIcon icon={faSpaceShuttle} size="2x" />
      </button>
    </>
  )
}

export default BtnMenu
